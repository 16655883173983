<script setup>
import { HomeIcon, FireIcon, UsersIcon, FilmIcon, MusicalNoteIcon, AcademicCapIcon, NewspaperIcon, RocketLaunchIcon, TvIcon } from '@heroicons/vue/24/outline'
import {onMounted, ref} from "vue";
import {windowService} from "@/services/windowService.js";

const categories = [
    { name: 'Home', icon: HomeIcon, href: '/' },
    { name: 'Trending', icon: FireIcon, href: '/trending' },
    { name: 'Following', icon: UsersIcon, href: '/following' },
    { name: 'Gaming', icon: TvIcon, href: '/trending/gaming' },
    { name: 'Music', icon: MusicalNoteIcon, href: '/trending/music' },
    { name: 'Education', icon: AcademicCapIcon, href: '/trending/education' },
    { name: 'Tech', icon: RocketLaunchIcon, href: '/trending/tech' },
    { name: 'Entertainment', icon: FilmIcon, href: '/trending/entertainment' },
    { name: 'News', icon: NewspaperIcon, href: '/trending/news' },
]

const isMobile = windowService();
</script>

<template>
    <aside v-if="!isMobile" style="z-index: 5;" class="w-20 fixed left-0 top-16 h-full bg-black/50 backdrop-blur-lg border-r border-gray-800/50 group hover:w-56 transition-all duration-300 z-40">
        <div class="p-2">
            <ul class="space-y-1">
                <li v-for="category in categories" :key="category.name">
                    <a :href="category.href" class="flex items-center px-3 py-2.5 text-gray-400 hover:bg-gray-800/50 hover:text-purple-400 rounded-lg transition-colors duration-200">
                        <component :is="category.icon" class="h-5 w-5 min-w-[20px]" />
                        <span class="ml-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
                          {{ category.name }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </aside>
</template>
